import { useMemo } from "react";
import Table from "react-bootstrap/Table";
import { roundOptions } from "store/filtersSlice";
import { Game } from "../../../types/API/WarGamingAPI";

type propsType = {
  game: Game;
};

type accType = {
  [player: string]: any;
};

type roundType = {
  viewable_impressions: number[];
  know_to_think: number[];
  think_to_do: number[];
  round_revenue: number[];
};

const ACOTables = (props: propsType) => {
  const map: any = {
    viewable_impressions: "Viewable Impressions",
    unaware_to_know: "100% Video Views",
    know_to_think: "Clicks (website link)",
    think_to_do: "Clicks (purchase link)",
    round_revenue: "Round Revenue ($)",
  };

  let mapKeys = Object.keys(map);
  
  const getNoRoundsMessage = () => {
    return (
      <>
        {!props?.game?.results ? (
          <p className="noRoundMsg">
            There are no round results available for this game yet.
          </p>
        ) : null}
      </>
    );
  };

  const numberFormatter = Intl.NumberFormat('en-US');
  const accumulatedData = useMemo(() => {
    return props?.game?.results?.rounds.reduce((acc: accType, round: any) => {
      round.players.forEach((player: any) => {
        if (!acc[player.id]) {
          acc[player.id] = {
            viewable_impressions: [numberFormatter.format(player.metrics.round_viewable_impressions.toFixed(0))],
            unaware_to_know: [numberFormatter.format(player.metrics.unaware_to_know.toFixed(0))],
            know_to_think: [numberFormatter.format(player.metrics.know_to_think.toFixed(0))],
            think_to_do: [numberFormatter.format(player.metrics.think_to_do.toFixed(0))],
            round_revenue: [numberFormatter.format(player.metrics.round_revenue.toFixed(0))],
          };
        } else {
          acc[player.id].viewable_impressions.push(numberFormatter.format(player.metrics.round_viewable_impressions.toFixed(0)));
          acc[player.id].unaware_to_know.push(numberFormatter.format(player.metrics.unaware_to_know.toFixed(0)));
          acc[player.id].know_to_think.push(numberFormatter.format(player.metrics.know_to_think.toFixed(0)));
          acc[player.id].think_to_do.push(numberFormatter.format(player.metrics.think_to_do.toFixed(0)));
          acc[player.id].round_revenue.push(numberFormatter.format(player.metrics.round_revenue.toFixed(0)));
        }
      });
      return acc;
    }, {});
  }, [props]);
  const playerList = Object.keys(accumulatedData || {});

  return (
    <>
      {getNoRoundsMessage()}
      {props?.game?.results?.rounds.map((round, roundNo) => {
        return (
          <div key={roundNo}>
            <div className="roundHeading">Round {roundNo + 1}</div>
            <br />

            <Table bordered>
              <thead>
                <tr className='tableHeader'>
                  <th className ='columnWidth'>Media KPI</th>
                  {playerList.map((player: string) => {
                    return <th key={player}>{player}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {mapKeys.map((mediaKPI, index) => {
                  return (
                    <tr key={index}>
                      <td className='mediaKPIColumnACO'>  {map[mediaKPI]}</td>
                      {playerList.map((player: string) => {
                        return (
                          <td key={player}>
                            {accumulatedData &&
                              accumulatedData[player][mediaKPI][roundNo]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        );
      })}
    </>
  );
};

export default ACOTables;
