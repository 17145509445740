import { Game } from "types/API/WarGamingAPI"
import { AxisConfigOptions, GraphDisplayObject, MoveLabelMeta, MoveObj, RoundMoveObject } from "./Types"

export const borderColorArr = ['rgb(255, 99, 132)', 'rgb(53, 162, 235)', 'rgb(71, 166, 0)', 'rgb(238, 18, 176)', 'rgb(10, 139, 140)', 'rgb(84, 7, 115)']
export const backgroundColorArr = ['rgba(255, 99, 132,0.5)', 'rgba(53, 162, 235,0.5)', 'rgba(71, 166, 0,0.5)' , 'rgba(238, 18, 176, 0.5)', 'rgba(10, 139, 140, 0.5)', 'rgba(84, 7, 115, 0.5)']


export const getGraphObject = (data: (number | undefined)[], label: string | MoveLabelMeta, index: number, type: 'line' | 'bar', axisOptions?: AxisConfigOptions): GraphDisplayObject => ({
    label,
    data,
    type,
    borderColor: borderColorArr[index],
    backgroundColor: backgroundColorArr[index],
    yAxisID: axisOptions?.yAxisID,
    xAxisID: axisOptions?.xAxisID
})

export const getMoveSpan = (_startWeek: string, _endWeek: string, round: number, weeksInRound: string): { start: number, end: number, day: number } => {
    const DAYS_IN_WEEK = 7;

    const weeks = parseInt(weeksInRound, 10)
    
    const res = {
      start: 0,
      end: 0,
      day: 0
    }
    const startWeek = typeof _startWeek === 'string' ? parseInt(_startWeek, 10) : _startWeek;
    // const endWeek = typeof _endWeek === 'string' ? parseInt(_endWeek, 10) : _endWeek;
    
    const startWeekRound = startWeek + (round * weeks);

  
    res.start = startWeekRound;
    // res.end = endWeekLastDay;
    res.day = startWeekRound;
  
    return res;
}


export const getPlayerKey = (data: Game, playerIdParam: string): { key: string; index: number } => {
    const index = parseInt(playerIdParam, 10)
    return {
      key: data.players[index].id,
      index,
    }
};


export function toFixed (value: number, positions = 2): number {
  return parseFloat(value.toFixed(positions));
}

export function toFixedString (value: number, positions = 2): string {
    return value.toFixed(positions);
}