import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  HTFMove,
  HtfDefaultSetup,
  Move,
  Status,
  Type,
} from "../../types/API/WarGamingAPI";
import { v4 as uuidv4 } from "uuid";
import { StyledLabel } from "./Move";
import { FormProps, HTFFormData } from "./Types";
import { pushMovesData } from "./Utils";
import {
  updatePlayerGameStatus,
  updatePlayerRoundStatus,
} from "../../db/db-helpers";
export const generateHTFMove = (
  payload: HTFFormData,
  dbMoveId?: string
): HTFMove => ({
  id: dbMoveId || uuidv4().slice(0, 5),
  type: payload.type,
  //investment: +payload.investment,
  segment: payload.segment,
  channel: payload.channel,
  landingPage: payload.landingPage,
  creativeX: payload.creativeX / 100,
  optimisationKPI: payload.optimisationKPI,
});

export type HTFValues = {
  segment: string;
  channel: string;
  type: string;
  //investment: number,
  creativeX: number;
  landingPage: string;
  optimisationKPI: string;
};

export const getHTFDefaultValues = (): HTFValues => ({
  segment: "young_singles",
  channel: "facebook",
  type: Type.MoveHTF,
  //investment: 0,
  creativeX: 0.0,
  landingPage: "website_home_page",
  optimisationKPI: "CPCV",
});

export const HTFForm = (props: FormProps<Move>) => {
  const params = useParams<{
    gameId: string;
    playerId: string;
  }>();

  const [firstRoundSelection, setFirstRoundSelection] = useState<string>();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: getHTFDefaultValues(),
  });

  useEffect(() => {
    const firstRoundMove: any =
      props.gameInfo.players[+params.playerId].rounds[0].moves;
    if (firstRoundMove) {
      const keyFirstRoundMove = Object.keys(firstRoundMove)[0];
      setFirstRoundSelection(firstRoundMove[keyFirstRoundMove].segment);
    }
  }, [props.gameInfo]);

  useEffect(() => {
    if (props.moveByIDData?.id) {
      let moveData: HTFMove | Move;

      if (props.moveByIDData!.type === Type.MoveHTF) {
        moveData = props.moveByIDData as HTFMove;
      } else {
        moveData = props.moveByIDData as Move;
      }
      if (props.moveByIDData!.type === Type.MoveHTF) {
        moveData = props.moveByIDData as HTFMove;
      } else {
        moveData = props.moveByIDData as Move;
      }

      setValue("segment", props.moveByIDData!.segment);
      setValue("channel", props.moveByIDData!.channel);
      setValue("landingPage", props.moveByIDData!.landingPage);
      setValue("creativeX", props.moveByIDData!.creativeX * 100);
      setValue("optimisationKPI", props.moveByIDData!.optimisationKPI);
      setValue("investment", props.moveByIDData!.investment);
    }
  }, [props.moveByIDData, setValue]);

  const onSubmit = async (d: any) => {
    let move = generateHTFMove(d, props.moveByIDData?.id);

    pushMovesData(
      move,
      params.gameId,
      props.gameInfo.currentRound,
      params.playerId,
      props.moveByIDData?.id
    );

    props.getMovesData(move);
    props.getShowAddMoveForm(!props.getShowAddMoveForm);

    //update status of a player at the game level
    if (props.gameInfo.currentRound === HtfDefaultSetup.numberOfRounds - 1) {
      await updatePlayerGameStatus({
        gameId: params.gameId,
        playerId: params.playerId,
        status: Status.Ready,
      });
    }

    //update status of a player at the round level
    await updatePlayerRoundStatus({
      gameId: params.gameId,
      playerId: params.playerId,
      status: Status.Ready,
      currentRound: props.gameInfo.currentRound,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row style={{ minHeight: "8rem" }}>
        <Col md="auto" style={{ minWidth: "19rem" }}>
          <StyledLabel>Segment</StyledLabel>
          <select
            {...register("segment")}
            disabled={props.disable || firstRoundSelection ? true : false}
            defaultValue={firstRoundSelection}
          >
            <option value="young_singles">Young Singles</option>
            <option value="young_families">Young Families</option>
            <option value="established_families">Established Families</option>
          </select>
        </Col>

        {/* <Col md="auto" style={{ minWidth: '19rem' }}>
        {/* <Col md="auto" style={{ minWidth: '19rem' }}>
                    <StyledLabel>Channel</StyledLabel>
                    <select {...register("channel")}>
                        <option value="meta">Meta</option>
                        <option value="youtube">Youtube</option>
                    </select>
                </Col> */}

        <Col md="auto" style={{ width: "19rem" }}>
          <StyledLabel>Creative Score (%)</StyledLabel>
          <input
            type="number"
            {...register("creativeX", { min: 0.0, max: 100.0 })}
            min={0.0}
            max={100.0}
            step={0.1}
            disabled={props.disable}
            // value={amount}
            // onChange={e => setAmount(parseInt(e.target.value, 10))}
          />
        </Col>

        <Col md="auto" style={{ minWidth: "19rem" }}>
          <StyledLabel>Landing Page</StyledLabel>
          <select {...register("landingPage")} disabled={props.disable}>
            <option value="website_home_page">Brand Website - Home Page</option>
            <option value="website_product_page">
              Brand Website - Product Page
            </option>
            <option value="amazon_product_page">Amazon Product Page</option>
          </select>
        </Col>

        <Col md="auto" style={{ minWidth: "19rem" }}>
          <StyledLabel>Optimisation KPI</StyledLabel>
          <select {...register("optimisationKPI")} disabled={props.disable}>
            <option value="CPCV">Cost per Completed View</option>
            <option value="CPA">Cost per Action (Landing page visit)</option>
            <option value="CPC">Cost per Click</option>
          </select>
        </Col>
      </Row>

      {/* <Row style={{ minHeight: '8rem' }}>
      {/* <Row style={{ minHeight: '8rem' }}>
                <Col md="auto" style={{ width: '19rem' }}>
                    <StyledLabel>Investment ($)</StyledLabel>
                    <input type="number" {...register("investment", { min: 0.0, max: 1e8 }) }
                        min={0.0}
                        max={1e8}
                        // value={amount}
                        // onChange={e => setAmount(parseInt(e.target.value, 10))} 
                        />
                </Col>

            </Row> */}
      <Button
        type="submit"
        variant="dark"
        style={{ minWidth: "12rem" }}
        disabled={props.disable}
      >
        Confirm
      </Button>
    </form>
  );
};
