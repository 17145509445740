import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Div } from "../../elements/Div";
import "bootswatch/dist/slate/bootstrap.min.css";
import ACOForm from "./ACOForm";
import { HTFForm } from "./HTFForm";
import { GameTypeEnum } from "../../types/API/WarGamingAPI";

export const StyledLabel = styled.label`
  display: block;
`;

export default function Move(props: any) {
  const [moveByIDData, setMoveByIDData] = useState<any>();
  const [maxWeek, setMaxWeek] = useState<number>(1);
  const [formType, setFormType] = useState<string>("");

  useEffect(() => {
    if (props.gameInfo) {
      setMaxWeek(Math.round(props.gameInfo.days / 7) - 1);
      setFormType(props.gameInfo.gameType);
    }
  }, [props.gameInfo]);

  // set values on edit
  useEffect(() => {
    if (props.moveByIDData) {
      setMoveByIDData(props.moveByIDData);
    }
  }, [props.moveByIDData, moveByIDData]);

  return (
    <>
      <Container fluid className="flex-column flex-grow-1 moves-form-container">
        <Row className="h-100">
          <Col md="auto" className="p-4">
            <Div className="container-moves">
              <Container fluid>
                {!props.gameInfo && <p>Loading...</p>}
                {props.gameInfo && formType === GameTypeEnum.ACO && (
                  <ACOForm {...props} maxWeek={maxWeek} />
                )}
                {props.gameInfo && formType === GameTypeEnum.HTF && (
                  <HTFForm {...props} maxWeek={maxWeek} />
                )}
              </Container>
            </Div>
            {props.counter && props.disable && !props.gameFinished && (
              <div className="counter">
                <span className="small-font">Your move was saved!</span>
                <span>
                  Players ready: {props.counter.playersReady} out of{" "}
                  {props.counter.numberOfPlayers}
                </span>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
