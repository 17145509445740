import { ReactElement, useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";

import { useParams } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { db } from "../../../db/firebase";
import { Game, Status, Player } from "../../../types/API/WarGamingAPI";
import { useForm } from "react-hook-form";

export default function ModerateGame(): ReactElement {
  const [gameInfo, setGameInfo] = useState<Game>();
  const [players, setPlayers] = useState<Player[]>();
  const [resetOption, setResetOption] = useState<string>();
  const params = useParams<{ gameId: string }>();

  const gameRef = ref(db, `games/${params.gameId}`);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    //observer for the current round
    onValue(gameRef, (snapshot) => {
      const game = snapshot.val();
      setGameInfo(game);

      const players = game.players;

      setPlayers(players);
    });
  }, []);

  return (
    <Container fluid className="flex-column flex-grow-1 homePageContainer ">
      <Container fluid>
        <Row
          style={{ minHeight: "8rem", maxWidth: "980px" }}
          className="mt-5  change-flex-mobile d-flex flex-column"
        >
          {gameInfo && (
            <>
              <h1>Welcome to game: {gameInfo.name}</h1>
              <h1>Round number: {gameInfo.currentRound + 1}</h1>
              <h1>Players: {players && players.length}</h1>
              {gameInfo.gameType && <h1>Game Type: {gameInfo.gameType}</h1>}
            </>
          )}
          <div className="leaderboard-heading mt-5">Players status</div>
          <div className="w-100">
            {players && (
              <Table bordered>
                <thead>
                  <tr className="tableHeader">
                    <th>Player</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {players.map((player: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{player.id}</td>
                        <td>
                          {gameInfo && player.rounds[gameInfo?.currentRound]
                            ? player.rounds[
                                gameInfo?.currentRound
                              ].hasOwnProperty("status")
                              ? player.rounds[gameInfo?.currentRound].status
                              : Status.Working
                            : Status.Working}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            <Col
              md="auto"
              className="d-flex mt-5 justify-content-between flex-wrap change-flex-mobile "
            >
              <Button
                type="submit"
                variant="dark"
                className=" align-center-mobile align-self-end mb-4"
              >
                Start simulation
              </Button>
              <form>
                <Col className="change-flex-mobile flex-grow-1">
                  <Col
                    md="auto"
                    className="d-flex flex-column flex-grow-1  change-flex-mobile mb-4 "
                  >
                    <Col md="auto" className=" d-flex flex-column ">
                      <select
                        {...register("reset", { required: true })}
                        onChange={(e) => {
                          setResetOption(e.target.value);
                        }}
                        className=" d-flex flex-grow-1 w-100"
                      >
                        <option value="">Select an option to reset</option>
                        <option value={0}>Reset current round</option>
                        <option value={1}>Reset game</option>
                      </select>
                      {errors?.channel?.type === "required" && (
                        <p className="reqFieldMsg">Please select a channel.</p>
                      )}
                    </Col>
                  </Col>
                  <Button
                    type="submit"
                    variant="outline-success"
                    className="align-self-end align-center-mobile mb-4"
                  >
                    Reset
                  </Button>
                </Col>
              </form>
            </Col>
          </div>
        </Row>
      </Container>
    </Container>
  );
}
