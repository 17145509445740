import { ReactElement } from 'react';
import { Button, Navbar } from 'react-bootstrap';
import logo from 'assets/Reckitt-Logo.png';
import { Link } from 'react-router-dom';

export default function TopNavBar(): ReactElement {

  // const userId = useAppSelector(selectUserId);

  return (
    <div>
      <Navbar variant="light" >
        <Link to="/">
          <Navbar.Brand>
            <img
              alt="Reckitt logo"
              src={logo}
              width="8%"
              height="8%"
              className="d-inline-block align-top mr-2"
            />
          Home
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle />
        {/* <Navbar.Collapse className="justify-content-end">
          <Dropdown>
            <Dropdown.Toggle variant="secondary" size="sm">
              Org, room
          </Dropdown.Toggle>
            <Dropdown.Menu alignRight style={{ width: '100%' }} >
              <Dropdown.Header>Current org name</Dropdown.Header>
              <Dropdown.Item href="#/action-1">Room 1</Dropdown.Item>
              <Dropdown.Item href="#/action-1">Room 2</Dropdown.Item>
              <Dropdown.Item href="#/action-1">Administer rooms</Dropdown.Item>
              <Dropdown.Item href="#/actions-1">Administer org</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Header>Org 2 name</Dropdown.Header>
              <Dropdown.Item href="#/action-1">Room 1</Dropdown.Item>
              <Dropdown.Item href="#/action-1">Room 2</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href="#/action-1">Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle variant="secondary" size="sm">
              {userId}
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight style={{ width: '100%' }} >
              <Dropdown.Item href="#/action-1">Settings</Dropdown.Item>
              <Dropdown.Item href="#/action-1">Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </Navbar.Collapse> */}
       
      </Navbar>
    </div>
  )
}
