import styled from "styled-components";

export const Div = styled.div`
    border-style: solid;
    border-radius: 0.5rem;
    border-width: 2px;
    border-color: 'secondary';
    padding: 1rem;
    height: 100%;
`;
