import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface authState {
    isAuthed : boolean
}

const initialState: authState = {
    isAuthed: false
}

export const authSlice = createSlice({
    name: 'authed',
    initialState,
    reducers: {
        setIsAuthed: (state, action: PayloadAction<boolean>) => {
            state.isAuthed = action.payload
        }
    }
});

export const { setIsAuthed } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;