import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { ACOMove, Move, Type } from "../../types/API/WarGamingAPI";
import { v4 as uuidv4 } from "uuid";
import { StyledLabel } from "./Move";
import { FormProps, ACOFormData } from "./Types";
import { pushMovesData, ACOformLabelMappings } from "./Utils";

const generateACOMove = (payload: ACOFormData, dbMoveId?: string): ACOMove => ({
  id: dbMoveId || uuidv4().slice(0, 5),
  type: payload.type || Type.MoveACO,
  channel: payload.channel,
  landingPage: payload.landingPage,
  adFormat: payload.adFormat,
  optimisationKPI: payload.optimisationKPI,
  investment: payload.investment,
});

export default function ACOForm(props: FormProps<ACOMove | Move>) {
  const params = useParams<{ gameId: string; playerId: string }>();
  const [adChannel, setChannel] = useState("");

  const getACODefaultValues = () => {
    return {
      investment: 0,
      type: Type.MoveACO,
    };
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm<any>({
    defaultValues: getACODefaultValues(),
  });

  useEffect(() => {
    var channel = props?.moveByIDData?.channel || "";
    setChannel(channel);
  }, [props.moveByIDData]);

  const getAdFormatOptions = useMemo(() => {
    let options: JSX.Element[] = [
      <option value="">Select a creative option</option>,
    ];
    if (adChannel === "youtube") {
      options.push(
        ...[
          <option value="video_15s_skip">
            {ACOformLabelMappings.video_15s_skip}
          </option>,
          <option value="video_15s_non_skip">
            {ACOformLabelMappings.video_15s_non_skip}
          </option>,
          <option value="video_6s_bumper">
            {ACOformLabelMappings.video_6s_bumper}
          </option>,
        ]
      );
    } else if (adChannel === "facebook") {
      options.push(
        ...[
          <option value="video_15s_instream">
            {ACOformLabelMappings.video_15s_instream}
          </option>,
          <option value="video_6s_infeed">
            {ACOformLabelMappings.video_6s_infeed}
          </option>,
          <option value="video_6s_stories">
            {ACOformLabelMappings.video_6s_stories}
          </option>,
        ]
      );
    }
    return options;
  }, [adChannel]);

  useEffect(() => {
    if (props.moveByIDData?.id) {
      let moveData: ACOMove | Move;

      if (props.moveByIDData!.type === Type.MoveACO) {
        moveData = props.moveByIDData as ACOMove;
      } else {
        moveData = props.moveByIDData as Move;
      }
      setValue("channel", props.moveByIDData!.channel);
      setValue("landingPage", props.moveByIDData!.landingPage);
      setValue("optimisationKPI", props.moveByIDData!.optimisationKPI);
      setValue("investment", props.moveByIDData!.investment);
      setTimeout(() => {
        setValue("adFormat", props.moveByIDData!.adFormat);
        trigger();
      }, 100);
    }
  }, [props.moveByIDData, setValue]);

  return (
    <form
      onSubmit={handleSubmit((d) => {
        let move = generateACOMove(d, props.moveByIDData?.id);
        pushMovesData(
          move,
          params.gameId,
          props.gameInfo.currentRound,
          params.playerId,
          props.moveByIDData?.id
        );
      })}
    >
      <Row style={{ minHeight: "8rem" }}>
        <Col md="auto" style={{ minWidth: "19rem" }}>
          <StyledLabel>Channel</StyledLabel>
          <select
            disabled={props.disable}
            {...register("channel", { required: true })}
            onChange={(e) => {
              setChannel(e.target.value);
              reset({ adFormat: "" });
            }}
          >
            <option value="">Select a channel</option>
            <option value="facebook">{ACOformLabelMappings["facebook"]}</option>
            <option value="youtube">{ACOformLabelMappings["youtube"]}</option>
          </select>
          {errors?.channel?.type === "required" && (
            <p className="reqFieldMsg">Please select a channel.</p>
          )}
        </Col>
        <Col md="auto" style={{ minWidth: "19rem" }}>
          <StyledLabel>Creative Options</StyledLabel>
          <select
            {...register("adFormat", { required: true })}
            disabled={props.disable}
          >
            {getAdFormatOptions}
          </select>
          {errors?.adFormat?.type === "required" && (
            <p className="reqFieldMsg">Please select a creative option.</p>
          )}
        </Col>
        <Col md="auto" style={{ minWidth: "19rem" }}>
          <StyledLabel>Destination</StyledLabel>
          <select {...register("landingPage")} disabled={props.disable}>
            <option value="website_home_page">
              {ACOformLabelMappings["website_home_page"]}
            </option>
            <option value="website_product_page">
              {ACOformLabelMappings["website_product_page"]}
            </option>
            <option value="amazon_product_page">
              {ACOformLabelMappings["amazon_product_page"]}
            </option>
          </select>
        </Col>
        <Col md="auto" style={{ minWidth: "19rem" }}>
          <StyledLabel>Optimisation KPI</StyledLabel>
          <select {...register("optimisationKPI")} disabled={props.disable}>
            <option value="CPM">{ACOformLabelMappings["CPM"]}</option>
            <option value="CPCV">{ACOformLabelMappings["CPCV"]}</option>
            <option value="CPA">{ACOformLabelMappings["CPA"]}</option>
            <option value="CPC">{ACOformLabelMappings["CPC"]}</option>
          </select>
        </Col>
      </Row>
      <Row style={{ minHeight: "8rem" }}>
        <Col md="auto" style={{ width: "19rem" }}>
          <StyledLabel>Budget</StyledLabel>
          <input
            type="number"
            {...register("investment", { min: 0 })}
            min={0}
            disabled={props.disable}
          />
        </Col>
      </Row>
      <Button
        type="submit"
        variant="dark"
        style={{ minWidth: "12rem" }}
        disabled={props.disable}
      >
        Confirm
      </Button>
    </form>
  );
}
