import { createNewGame } from "../../../db/db-helpers";
import { ReactElement, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import styled from "styled-components";
import {
  GameInfo,
  GameTypeEnum,
  Player,
  Status,
  HtfDefaultSetup,
  AcoDefaultSetup,
} from "../../../types/API/WarGamingAPI";
import { Input } from "../../../elements/Input";
import { Div } from "../../../elements/Div";
import { Link, useHistory } from "react-router-dom";

export type CreateGame = Omit<GameInfo, "currentRound"> & {
  players: { id: string; name: string; status: string }[];
};

const StyledLabel = styled.label`
  display: block;
`;
const getPlayerDefaultValue = (): {
  id: string;
  name: string;
  status: string;
} => {
  return {
    id: "",
    name: "",
    status: Status.Working,
  };
};

export default function NewGame(): ReactElement {
  const history = useHistory();
  const [gameType, setGameType] = useState<string>(GameTypeEnum.HTF);
  let playersDefaultValues: Player[] = new Array(3).fill(
    getPlayerDefaultValue()
  );

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm<CreateGame>({
    defaultValues: {
      players: playersDefaultValues,
      nPlayers: 2,
      weeks: "13",
      rounds: 4,
      gameType: GameTypeEnum.HTF,
      sims_per_day: 200,
    },
  });

  const playerAmountWatcher = watch("nPlayers", 2);

  const { fields, append, remove, update } = useFieldArray({
    name: "players",
    control,
  });
  const onSubmit = (game: CreateGame) =>
    createNewGame(game).then((gameId) => {
      reset();
      history.push(`/?gameId=${gameId}`);
    });

  useEffect(() => {
    const defaultSetup: {
      [key: string]: any;
    } = {
      htf: HtfDefaultSetup,
      aco: AcoDefaultSetup,
    };
    setValue("rounds", defaultSetup[gameType].numberOfRounds);
    setValue("weeks", defaultSetup[gameType].numberOfWeeks);
  }, [gameType, setValue]);

  useEffect(() => {
    const n = playerAmountWatcher;
    const delta = n - fields.length;
    const sign = Math.sign(delta);
    switch (sign) {
      case 1:
        append(new Array(delta).fill(getPlayerDefaultValue()));
        break;

      case -1:
        remove(fields.length - 1);
        break;

      default:
        break;
    }
  }, [playerAmountWatcher, fields.length, append, remove]);

  return (
    <Container
      fluid
      className="flex-column flex-grow-1 justify-content-center align-items-center"
    >
      <Div>
        <Container fluid>
          <div className="flex-container">
            <h2>Create a new game</h2>
            <Link className="leaderboard-link" to="/leaderboard">
              Leaderboard
            </Link>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Row style={{ minHeight: "8rem" }}>
              <Col>
                <StyledLabel>Name your game</StyledLabel>
                <Input
                  {...register("name", {
                    required: true,
                    maxLength: 30,
                    minLength: 3,
                  })}
                  maxLength={30}
                  minLength={3}
                />
                {errors.name && <p>A name is required</p>}
              </Col>
            </Row>
            <Row style={{ minHeight: "8rem" }}>
              <Col md="auto" style={{ minWidth: "19rem" }}>
                <StyledLabel>Number of rounds</StyledLabel>
                <Input
                  type="number"
                  {...register("rounds", { required: true, min: 2, max: 4 })}
                  min={2}
                  max={4}
                  disabled={true}
                />
                {errors.rounds && <p>A number of rounds is required</p>}
              </Col>
              <Col md="auto" style={{ minWidth: "19rem" }}>
                <StyledLabel>Number of weeks per round</StyledLabel>
                <Input
                  type="number"
                  {...register("weeks", { required: true, min: 2, max: 52 })}
                  min={2}
                  max={52}
                  disabled={true}
                />
                {errors.weeks && <p>A number of weeks per round is required</p>}
              </Col>
              <Col md="auto" style={{ minWidth: "19rem" }}>
                <StyledLabel>Number of players</StyledLabel>
                <Input
                  type="number"
                  {...register("nPlayers", { required: true, min: 1, max: 10 })}
                  min={1}
                  max={10}
                />
                {errors.nPlayers && <p>A number of players is required</p>}
              </Col>
            </Row>

            <Row style={{ minHeight: "8rem" }}>
              <Col md="auto" style={{ minWidth: "19rem" }}>
                <StyledLabel>Sims per day</StyledLabel>
                <Input
                  type="number"
                  {...register("sims_per_day", { required: true, min: 50 })}
                  min={50}
                />
                {errors.rounds && <p>Shops per day is required</p>}
              </Col>

              <Col md="auto" style={{ minWidth: "19rem" }}>
                <StyledLabel>Game type</StyledLabel>
                <div className="form-group--inline">
                  <input
                    type="radio"
                    value={GameTypeEnum.HTF}
                    {...register("gameType", { required: true })}
                    onChange={(event) => setGameType(event.target.value)}
                  />
                  <StyledLabel>HTF</StyledLabel>
                </div>
                <div className="form-group--inline">
                  <input
                    type="radio"
                    value={GameTypeEnum.ACO}
                    {...register("gameType", { required: true })}
                    onChange={(event) => setGameType(event.target.value)}
                  />
                  <StyledLabel>ACO</StyledLabel>
                </div>
                {errors.rounds && <p>Game type is required</p>}
              </Col>
            </Row>
            <h2>Invite players</h2>
            <StyledLabel>Add players name</StyledLabel>
            {fields.map((field, index) => (
              <Row key={`r-${field.id}`} className="my-3">
                <Col>
                  <Input
                    type="string"
                    key={field.id}
                    {...register(`players.${index}.name` as const, {
                      required: true,
                    })}
                  />
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <Button
                    key={`b${field.id}`}
                    variant="secondary"
                    onClick={() =>
                      update(index, {
                        id: field.id,
                        name: "",
                        status: Status.Working,
                      })
                    }
                    size="sm"
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            ))}
            <Button
              type="submit"
              variant="success"
              style={{ minWidth: "12rem" }}
            >
              Submit
            </Button>
          </form>
        </Container>
      </Div>
    </Container>
  );
}
