import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, get, child } from "firebase/database";
import {
  SAMLAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup,
} from "firebase/auth";

let fbConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(fbConfig);

const auth = getAuth(app);

const db = getDatabase(app);
const dbRef = ref(getDatabase());
// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const getValueChild = async (childNode: string) => {
  return (await get(child(dbRef, childNode))).val();
};
const provider = new SAMLAuthProvider("saml.reckitt-okta");

const ssoLogin = async () => {
  console.log("sso login");

  signInWithRedirect(auth, provider)
    .then((result) => {
      console.log(result);

      // User is signed in.

      // Provider data available using getAdditionalUserInfo()
    })
    .catch((error) => {
      // Handle error.
    });
};

const getSsoUser = async () => {
  const user = await getRedirectResult(auth);

  console.log(user);
};
export { db, auth, dbRef, getValueChild, ssoLogin, getSsoUser };
