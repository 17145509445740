import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Tick,
} from "chart.js";
import { Line } from "react-chartjs-2";
import FinanceTable from "./FinanceTable";
import { FinancialsInterface } from "../Types";
import FinanceTablePC from "./FinanceTablePercentageChange";
import { Col, Row } from "react-bootstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const data = (labels: string[], data: number[], datasetLabel: string) => ({
  labels,
  datasets: [
    {
      label: datasetLabel,
      data,
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    },
  ],
});

export const options = (text: string) => ({
  responsive: true,
  layout: {
    padding: 100,
  },
  plugins: {
    title: {
      display: true,
      text,
      color: "#fff",
      font: {
        size: 16,
      },
    },
  },
  scales: {
    y: {
      ticks: {
        // Include a £ sign in the ticks
        callback: function (
          value: string | number,
          _index: number,
          _ticks: Tick[]
        ) {
          return "£" + value.toLocaleString();
        },
        precision: 0,
      },
      title: {
        display: true,
        text: text,
        color: "#aaa",
      },
    },
  },
});

const Financials = ({ gameData, playerId }: FinancialsInterface) => {
  const [graphLabels, setGraphLabels] = useState<string[]>([]);
  const [revenue, setRevenue] = useState<number[]>([]);
  const [basketCogs, setBasketCogs] = useState<number[]>([]);
  const [grossMargin, setGrossMargin] = useState<number[]>([]);
  const [opex, setOpex] = useState<number[]>([]);
  const [ebitda, setEbitda] = useState<number[]>([]);
  const [avgBasketValue, setAverageBaskedValue] = useState<number[]>([]);
  const [volOfBasket, setVolOfBasket] = useState<number[]>([]);
  const [shareOfBasketVol, setShareOfBasketVol] = useState<number[]>([]);
  const [capitalAvailable, setCapitalAvailable] = useState<number[]>([]);
  const [captialSpent, setCaptialSpent] = useState<number[]>([]);
  const [captialRolledOver, setCapitalRolledOver] = useState<number[]>([]);
  const [playerNames, setPlayerNames] = useState<string[]>([]);
  const [endDates, setEndDates] = useState<string[]>([]);
  const [basketCost, setBasketCost] = useState<number[]>([]);
  const [ACOGoodsCost, setACOGoodsCost] = useState<number[]>([]);
  const [carbonTax, setCarbonTax] = useState<number[]>([]);

  useEffect(() => {
    const { results, players } = gameData;

    let playerName = "";

    if (players) {
      const gamePlayers = players.map((player) => player.id);
      setPlayerNames(gamePlayers);
      playerName = gamePlayers[playerId];
    }

    if (results) {
      const endDatesArray = Object.values(results).map((round) => {
        return round.end_date;
      });

      setEndDates(endDatesArray);

      setRevenue(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.revenue;
        })
      );

      setBasketCogs(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.basket_cogs;
        })
      );

      setGrossMargin(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.gross_margin;
        })
      );

      setOpex(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.opex;
        })
      );

      setEbitda(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.ebitda;
        })
      );

      setAverageBaskedValue(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.avg_basket_value;
        })
      );

      setVolOfBasket(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.vol_of_basket;
        })
      );

      setShareOfBasketVol(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.share_of_basket_vol;
        })
      );

      setCapitalAvailable(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.capital_available;
        })
      );

      setCaptialSpent(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.capital_spent;
        })
      );

      setCapitalRolledOver(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.capital_rolled_over;
        })
      );

      setBasketCost(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.basket_cost;
        })
      );

      setACOGoodsCost(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.aco_score_cost;
        })
      );

      setCarbonTax(
        results.rounds.map((round) => {
          return round.players[playerId].metrics.carbon_tax;
        })
      );

      setGraphLabels(endDatesArray);
    }
  }, [gameData, playerId]);
  return (
    <>
      <Row>
        <Col xs={12} lg={8}>
          <FinanceTable
            revenue={revenue}
            basketCogs={basketCogs}
            grossMargin={grossMargin}
            opex={opex}
            ebitda={ebitda}
            avgBasketValue={avgBasketValue}
            volOfBasket={volOfBasket}
            shareOfBasketVol={shareOfBasketVol}
            capitalAvailable={capitalAvailable}
            captialSpent={captialSpent}
            captialRolledOver={captialRolledOver}
            playerNames={playerNames}
            endDates={endDates}
            basketCost={basketCost}
            ACOGoodsCost={ACOGoodsCost}
            carbonTax={carbonTax}
            playerId={playerId}
          />
        </Col>
        <Col>
          <FinanceTablePC gameData={gameData} playerId={playerId} />
        </Col>
      </Row>

      <Line
        options={options("Revenue")}
        data={data(graphLabels, revenue, playerNames[playerId])}
      />
      <Line
        options={options("Investment Capital Spent")}
        data={data(graphLabels, captialSpent, playerNames[playerId])}
      />
      <Line
        options={options("Gross Margin")}
        data={data(graphLabels, grossMargin, playerNames[playerId])}
      />
      <Line
        options={options("EBITDA")}
        data={data(graphLabels, ebitda, playerNames[playerId])}
      />
    </>
  );
};

export default Financials;
