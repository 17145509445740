import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  Game,
  GameTypeEnum,
  Status,
  Counter,
  Player,
  HtfDefaultSetup,
  AcoDefaultSetup,
} from "../../types/API/WarGamingAPI";
import DisplayMoves from "./DisplayMoves";
import Move from "./Move";
import { onValue, ref } from "firebase/database";
import { db } from "../../db/firebase";
import {
  updatePlayerGameStatus,
  updatePlayerRoundStatus,
} from "../../db/db-helpers";

export default function Moves() {
  const [movesData, setMovesData] = useState([]);
  const [showAddMoveState, setShowAddMoveState] = useState<boolean>(true);
  const [gameFinished, setGameFinished] = useState<boolean>(true);
  const [currentRound, setCurrentRound] = useState<number>(0);
  const [moveByIDData, setMoveByIDData] = useState<any>();
  const [gameInfo, setGameInfo] = useState<Game>();
  const [counter, setCounter] = useState<Counter>();
  const [currentPlayer, setCurrentPlayer] = useState<Player>();
  const params = useParams<{ gameId: string; playerId: string }>();
  const playerId = parseInt(params.playerId, 10);

  // getAllMoves from Move Fom and send to Display Data component
  const getMovesData = (data: any) => {
    let allMoves: any = [...movesData, data];
    setMovesData(allMoves);
  };

  const getShowAddMoveForm = (data: boolean) => {
    setShowAddMoveState(data);
  };

  const getMoveByIDData = (data: any) => {
    setMoveByIDData(data);
  };

  const onAddMove = async () => {
    setShowAddMoveState(!showAddMoveState);
    setMoveByIDData({});
    // await updatePlayerGameStatus({
    //   gameId: params.gameId,
    //   playerId: params.playerId,
    //   status: Status.Working,
    // });
    if (gameInfo?.currentRound) {
      //update status of a player at the round level
      await updatePlayerRoundStatus({
        gameId: params.gameId,
        playerId: params.playerId,
        status: Status.Working,
        currentRound: gameInfo.currentRound,
      });
    }
  };

  const gameRef = ref(db, `games/${params.gameId}`);

  useEffect(() => {
    //observer for the current round
    onValue(gameRef, (snapshot) => {
      const game = snapshot.val();
      setGameInfo(game);
      const getCurrentPlayer = game.players[playerId];
      setCurrentPlayer(getCurrentPlayer);

      if (getCurrentPlayer.rounds[game.currentRound]?.status === Status.Ready) {
        setShowAddMoveState(false);
      } else {
        setShowAddMoveState(true);
      }
      const players = game.players;
      setCounter({
        numberOfPlayers: game.nPlayers,
        playersReady: players.filter(
          (player: Player) =>
            player.rounds[game.currentRound]?.status === Status.Ready
        ).length,
      });
      const isGameHtf = game.gameType === GameTypeEnum.HTF;
      const isLastRoundForHtf =
        game.currentRound === HtfDefaultSetup.numberOfRounds;

      if (isGameHtf) {
        if (isLastRoundForHtf) {
          setShowAddMoveState(false);
          setGameFinished(true);
          setCurrentRound(HtfDefaultSetup.numberOfRounds);
        } else {
          setCurrentRound(game.currentRound + 1);
          setGameFinished(false);
        }
      }

      const isLastRoundForAco =
        game.currentRound === AcoDefaultSetup.numberOfRounds;

      if (!isGameHtf) {
        if (isLastRoundForAco) {
          setShowAddMoveState(false);
          setGameFinished(true);
          setCurrentRound(AcoDefaultSetup.numberOfRounds);
        } else {
          setCurrentRound(game.currentRound + 1);
          setGameFinished(false);
        }
      }
    });
  }, []);

  return (
    <div>
      {gameInfo && (
        <>
          <h1>Welcome to game: {gameInfo.name}</h1>
          <h1>Round number: {currentRound}</h1>
          {playerId !== undefined && (
            <h1>Player: {gameInfo.players[playerId].id}</h1>
          )}
          {gameInfo.gameType && <h1>Game Type: {gameInfo.gameType}</h1>}
          <h1>Create and edit your moves </h1>
        </>
      )}

      {!showAddMoveState &&
      gameInfo?.gameType !== GameTypeEnum.HTF &&
      !gameFinished ? (
        <Button variant="outline-success" size="lg" onClick={() => onAddMove()}>
          Add a move
        </Button>
      ) : (
        ""
      )}

      <Move
        getMovesData={getMovesData}
        getShowAddMoveForm={getShowAddMoveForm}
        moveByIDData={moveByIDData}
        gameInfo={gameInfo}
        disable={!showAddMoveState}
        counter={counter}
        gameFinished={gameFinished}
      />

      {gameInfo && (
        <DisplayMoves
          movesData={movesData}
          getMoveByIDData={getMoveByIDData}
          getShowAddMoveForm={getShowAddMoveForm}
          gameInfo={gameInfo}
          currentPlayer={currentPlayer}
          disable={!showAddMoveState}
        />
      )}
    </div>
  );
}
