import { useHistory, useLocation } from "react-router";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Div } from "../../elements/Div";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Player } from "../../types/API/WarGamingAPI";
import { useDispatch } from "react-redux";
import { reset } from "../../store/apiSlice";
import { getValueChild } from "../../db/firebase";

const StyledLabel = styled.label`
  display: block;
`;

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Home(): ReactElement {
  const { register } = useForm<any>({});

  const query = useQuery();
  const dispatch = useDispatch();

  const [gameId, setGameId] = useState<string>("");

  const [players, setPlayers] = useState<Player[]>([]);

  const history = useHistory();
  const goToUrl = (url: string) => history.push(url);

  const getPlayersData = useCallback(async () => {
    if (!gameId.length) {
      setPlayers([]);
      return;
    }

    //get players for the current game
    const playersPath: string = `games/${gameId}/players`;
    const playersValue = await getValueChild(playersPath);
    if (playersValue) {
      setPlayers(playersValue);
    } else {
      setPlayers([]);
    }
  }, [gameId]);

  useEffect(() => {
    const queryID = query.get("gameId");
    if (queryID) {
      setGameId(queryID);
      getPlayersData();
    }
  }, [query, getPlayersData]);

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  return (
    <div className="homePageContainer">
      <Container fluid className="flex-column flex-grow-1">
        <Row className="h-100">
          <Col md="auto" className="p-4">
            <Div>
              <Container fluid>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    getPlayersData();
                  }}
                >
                  <Row style={{ minHeight: "8rem" }}>
                    <Col md="auto" style={{ width: "700px" }}>
                      <StyledLabel>Enter Game ID</StyledLabel>
                      <input
                        type="text"
                        {...register("gameIDField")}
                        value={gameId}
                        onChange={(e) => setGameId(e.target.value)}
                      ></input>
                      <Button
                        type="button"
                        variant="success"
                        style={{ minWidth: "12rem" }}
                        onClick={() => getPlayersData()}
                      >
                        Enter
                      </Button>
                    </Col>
                  </Row>
                  {Boolean(gameId.length) && Boolean(players.length) && (
                    <Row style={{ minHeight: "8rem" }}>
                      <Col md="auto" style={{ minWidth: "700px" }}>
                        <StyledLabel>Select Player</StyledLabel>
                        <select
                          {...register("playerNames")}
                          onChange={(e) => {
                            e.preventDefault();
                            goToUrl(`/game/${gameId}/player/${e.target.value}`);
                          }}
                        >
                          <option>Select Player</option>
                          {players.map(({ id }, i) => (
                            <option key={i} value={i}>
                              {id}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                  )}
                  {/* <Button type="submit" variant="success" style={{ minWidth: '12rem' }}>Confirm</Button> */}
                </form>
              </Container>
            </Div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
