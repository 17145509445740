import { CreateGame } from "components/pages/NewGame/NewGame";
import { Game, Status } from "../types/API/WarGamingAPI";
import { v4 as uuidv4 } from "uuid";
import { db } from "./firebase";
import { ref, set, update } from "firebase/database";
export const createNewGame = async (game: CreateGame): Promise<string> => {
  const days = parseInt(game.weeks, 10) * 7;
  const playersArr: any[] = game.players.map((player) => ({
    id: player.name,
    rounds: { 0: "" },
    status: Status.Working,
  }));

  const uid = uuidv4().slice(0, 4);
  // database reference
  const games = ref(db, `games/${uid}`);
  const payload: Game = {
    ...game,
    currentRound: 0,
    players: playersArr,
    days,
    uid,
  };

  // push data to firebase
  set(games, payload);

  return uid;
};

export const updatePlayerGameStatus = async ({
  gameId,
  playerId,
  status,
}: {
  gameId: string;
  playerId: string;
  status: Status;
}): Promise<void> => {
  const playerStatusDb = ref(db, `games/${gameId}/players/${playerId}`);
  await update(playerStatusDb, { status });
};

export const updatePlayerRoundStatus = async ({
  gameId,
  playerId,
  currentRound,
  status,
}: {
  gameId: string;
  playerId: string;
  currentRound: number;
  status: Status;
}): Promise<void> => {
  const playerStatusCurrentRoundDb = ref(
    db,
    `games/${gameId}/players/${playerId}/rounds/${currentRound}`
  );
  await update(playerStatusCurrentRoundDb, { status });
};
