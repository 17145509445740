import { ref, remove, update } from "firebase/database";
import { db, getValueChild } from "../../db/firebase";
import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  Game,
  Move,
  ACOMove,
  HTFMove,
  PlayerRound,
  Type,
  GameTypeEnum,
  Status,
  Player,
  AcoDefaultSetup,
} from "../../types/API/WarGamingAPI";
import { ACOformLabelMappings } from "./Utils";
import {
  updatePlayerGameStatus,
  updatePlayerRoundStatus,
} from "../../db/db-helpers";

type Props = {
  movesData: any;
  getMoveByIDData: any;
  getShowAddMoveForm: any;
  gameInfo: Game;
  currentPlayer: undefined | Player;
  disable: boolean;
};

export default function DisplayMoves(props: Props) {
  let { gameId, playerId } = useParams<{ gameId: string; playerId: string }>();

  const [finishedMoves, setFinishedMoves] = useState<boolean>(false);
  const [moves, setMoves] = useState<
    { round: number; moves: (Move | ACOMove | HTFMove)[] }[]
  >([]);

  const showMovesData = useCallback(async () => {
    const roundsPath: string = `games/${gameId}/players/${playerId}/rounds`;
    const roundsDb = await getValueChild(roundsPath);
    const rounds: PlayerRound[] = roundsDb || [];

    const newMoveState: {
      round: number;
      moves: (Move | ACOMove | HTFMove)[];
    }[] = rounds.map((round, index) => {
      let moveData: (Move | ACOMove | HTFMove)[] = [];

      if (typeof round !== "string" && round?.moves) {
        for (const moveId of Object.keys(round!.moves)) {
          moveData.push(round!.moves[moveId]);
        }
      }

      return {
        round: index,
        moves: moveData,
      };
    });

    newMoveState.sort((a, b) => b.round - a.round);
    setMoves(newMoveState);
  }, [gameId, playerId]);

  useEffect(() => {
    if (props.movesData) {
      showMovesData();
    }
  }, [props.movesData, showMovesData, props.gameInfo]);

  useEffect(() => {
    if (
      !props.gameInfo.players[+playerId].rounds[props.gameInfo.currentRound] ||
      !props.gameInfo.players[+playerId].rounds[
        props.gameInfo.currentRound
      ].hasOwnProperty("status") ||
      props.gameInfo.players[+playerId].rounds[props.gameInfo.currentRound]
        .status === Status.Working
    ) {
      setFinishedMoves(false);
    } else {
      setFinishedMoves(true);
    }
  }, [props.currentPlayer]);

  const deleteMove = async (moveId: string) => {
    if (moveId) {
      const moveDb = ref(
        db,
        `games/${gameId}/players/${playerId}/rounds/${props.gameInfo.currentRound}/moves/${moveId}`
      );
      await updatePlayerGameStatus({
        gameId,
        playerId,
        status: Status.Working,
      });

      await updatePlayerRoundStatus({
        gameId,
        playerId,
        status: Status.Working,
        currentRound: props.gameInfo.currentRound,
      });

      remove(moveDb);
      showMovesData();
    }
  };

  const editMove = async (moveId: string) => {
    if (moveId) {
      const movePath = `games/${gameId}/players/${playerId}/rounds/${props.gameInfo.currentRound}/moves/${moveId}`;

      const moveDb = await getValueChild(movePath);

      if (moveDb) {
        const gameData: Game = moveDb;

        if (gameData) {
          props.getMoveByIDData(moveDb);
        }
      }

      props.getShowAddMoveForm(true);

      await updatePlayerGameStatus({
        gameId,
        playerId,
        status: Status.Working,
      });

      if (props.gameInfo.currentRound) {
        await updatePlayerRoundStatus({
          gameId,
          playerId,
          status: Status.Working,
          currentRound: props.gameInfo.currentRound,
        });
      }
    }
  };

  const updateStatus = async () => {
    if (props.gameInfo.currentRound === AcoDefaultSetup.numberOfRounds - 1) {
      await updatePlayerGameStatus({
        gameId,
        playerId,
        status: Status.Ready,
      });
    }

    await updatePlayerRoundStatus({
      gameId,
      playerId,
      status: Status.Ready,
      currentRound: props.gameInfo.currentRound,
    });
    setFinishedMoves(!finishedMoves);
  };

  const renderACOContent = (_move: Move | ACOMove | HTFMove) => {
    const move = _move as ACOMove;
    const channel = `${move.channel}` as keyof typeof ACOformLabelMappings;
    const landingPage =
      `${move.landingPage}` as keyof typeof ACOformLabelMappings;
    const optimisationKPI =
      `${move.optimisationKPI}` as keyof typeof ACOformLabelMappings;
    const adFormat = `${move.adFormat}` as keyof typeof ACOformLabelMappings;

    return (
      <div>
        You are spending $<b>{move.investment}</b> on{" "}
        <b>{ACOformLabelMappings[channel]}</b>, destination is{" "}
        <b>{ACOformLabelMappings[landingPage]}</b>,{" "}
        <b>{ACOformLabelMappings[optimisationKPI]}</b> as optimisation KPI and{" "}
        <b>{ACOformLabelMappings[adFormat]}</b> as creative option.
      </div>
    );
  };

  const renderHTFContent = (_move: Move | ACOMove | HTFMove) => {
    const move = _move as Move;
    var segmentMove = "Young Families";
    var destination = "Brand Website - Home Page";
    let type: string = move.type;

    if (move.type === Type.Price) {
      type = `price customers will pay`;
    }

    if (move.segment === "young_singles") {
      segmentMove = "Young Singles";
    } else if (move.segment === "established_families") {
      segmentMove = "Established Families";
    }

    if (move.landingPage === "website_product_page") {
      destination = "Brand Website - Product Page";
    } else if (move.landingPage === "amazon_product_page") {
      destination = "Amazon Product Page";
    }

    return (
      <div>
        Campaign targeting {segmentMove} using {move.channel} with{" "}
        {move.optimisationKPI} optimisation KPI. Destination will be{" "}
        {destination} and the creative score is{" "}
        {(move.creativeX * 100).toFixed(1)}%
      </div>
    );
  };

  return (
    <div className="displayMoveTable">
      {!finishedMoves &&
        props.gameInfo.gameType !== GameTypeEnum.HTF &&
        !props.disable && (
          <Button
            className="moves-area-round__complete"
            type="button"
            variant="outline-success"
            size="lg"
            style={{ minWidth: "12rem" }}
            onClick={() => updateStatus()}
          >
            Finished adding moves
          </Button>
        )}

      {moves.map((moveWrapper) => {
        if (!moveWrapper.moves.length) {
          return null;
        }

        return (
          <>
            <h1>Added Moves </h1>
            <div key={moveWrapper.round}>
              <div className="moves-area-round">
                <span>
                  Round {moveWrapper.round + 1}
                  {moveWrapper.round === props.gameInfo?.currentRound
                    ? " (Current)"
                    : ``}
                </span>
              </div>
              <div className="move-container">
                {moveWrapper.moves.length ? (
                  moveWrapper.moves.map((move) => {
                    return (
                      <div className="move" key={move.id}>
                        <span>
                          {move.type === Type.MoveACO
                            ? renderACOContent(move)
                            : renderHTFContent(move)}
                        </span>
                        {moveWrapper.round === props.gameInfo?.currentRound && (
                          <Button
                            type="button"
                            variant="secondary"
                            style={{ minWidth: "6rem" }}
                            onClick={() => editMove(move.id)}
                          >
                            Edit
                          </Button>
                        )}
                        {moveWrapper.round === props.gameInfo?.currentRound && (
                          <Button
                            type="button"
                            variant="danger"
                            style={{ minWidth: "6rem" }}
                            onClick={() => deleteMove(move.id)}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="move">No Moves inserted for this round </div>
                )}
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}
