import { deepCopy } from "@firebase/util";
import Table from "react-bootstrap/Table";
import { SummaryProps } from "../Types";

export default function HTFSummary(props: SummaryProps) {
  let accumulatedData: any;
  let playerList: string[] = [];

  const getGeneratedColumns = (
    fragment: string,
    accumulatedData: any,
    prop: string
  ) => {
    const tdList = [];
    const data = accumulatedData ? accumulatedData[fragment + "_data"] : {};
    const decimals = prop.endsWith('_pct') ? 1:0;
  
    
    for (const key in data) {

      let val = accumulatedData[fragment + "_data"][key][prop].toFixed(decimals);
      const numberFormatter = Intl.NumberFormat('en-US');
      const formattedVal = numberFormatter.format(val);
      tdList.push(<td>{formattedVal}</td>);
    }
    return <>{tdList}</>;
  };

  props.data.results?.rounds.forEach((round: any, roundIndex: number) => {
    const res = round.players.reduce((acc: any, pl: any) => {
      // Calculate know data from round 1
      if (roundIndex === 0) {
        if (acc.know_data) {
          acc.know_data = {
            ...acc.know_data,
            [pl.id]: {
              unaware_to_know: pl.metrics.unaware_to_know,
              unaware_to_know_pct: pl.metrics.unaware_to_know_pct,
            },
          };
        } else {
          acc.know_data = {
            [pl.id]: {
              unaware_to_know: pl.metrics.unaware_to_know,
              unaware_to_know_pct: pl.metrics.unaware_to_know_pct,
            },
          };
        }
      }

      // Calculate Think data from round 2
      if (roundIndex === 1) {
        if (acc.think_data) {
          acc.think_data = {
            ...acc.think_data,
            [pl.id]: {
              know_to_think: pl.metrics.know_to_think,
              know_to_think_pct: pl.metrics.know_to_think_pct,
            },
          };
        } else {
          acc.think_data = {
            [pl.id]: {
              know_to_think: pl.metrics.know_to_think,
              know_to_think_pct: pl.metrics.know_to_think_pct,
            },
          };
        }
      }

      // Calculate DO data from round 3
      if (roundIndex === 2) {
        if (acc.do_data) {
          acc.do_data = {
            ...acc.do_data,
            [pl.id]: {
              think_to_do: pl.metrics.think_to_do,
              think_to_do_pct: pl.metrics.think_to_do_pct,
            },
          };
        } else {
          acc.do_data = {
            [pl.id]: {
              think_to_do: pl.metrics.think_to_do,
              think_to_do_pct: pl.metrics.think_to_do_pct,
            },
          };
        }
      }
      return acc;
    }, {});

    accumulatedData = {
      ...accumulatedData,
      ...res,
    };

  });

  // Get player scores
  const playerScores: any = accumulatedData && Object.values(accumulatedData).reduce((acc: any , obj: any, ind: number) => {
    for(const player in obj) {
        let val = 0;
        for(const key in obj[player]) {
            if(key.endsWith('_pct')){
                val = obj[player][key];
            }
        }
        if(acc[player])
            acc[player] = acc[player] * val;
        else
            acc[player] = val;
    }
    return acc;
  }, {});
  
  playerList = Object.keys(accumulatedData?.know_data || {});

  const noOfRounds = props.data.results?.rounds?.length;

  return (
    <>
      {noOfRounds ? (
        <div>
          <div className="roundHeading">Round 1</div>
          <Table bordered  >
            <thead>
              <tr className='tableHeader' >
                <td className ='columnWidth' rowSpan={2}>Funnel Stage</td>
                <td className ='columnWidth'>Media KPI</td>
                {playerList?.map((player: string) => {
                  return <th key={player} className ='columnWidthPlayer'>{player}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr >
                <td rowSpan={2} className="headerTd">
                  KNOW
                </td>
                <td>100% Video views</td>
                {getGeneratedColumns(
                  "know",
                  accumulatedData,
                  "unaware_to_know"
                )}
              </tr>
              <tr>
                <td>Conversion Rate (%)</td>
                {getGeneratedColumns(
                  "know",
                  accumulatedData,
                  "unaware_to_know_pct"
                )}
              </tr>
            </tbody>
          </Table>

          {accumulatedData.think_data ? (
            <>
              <div className="roundHeading">Round 2</div>
              <Table bordered >
                <thead >
                  <tr className='tableHeader' >
                    <td className ='columnWidth' rowSpan={2}>Funnel Stage</td>
                    <td className ='columnWidth'>Media KPI</td>
                    {playerList?.map((player: string) => {
                      return <th key={player}>{player}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={2} className="headerTd">
                      Think
                    </td>
                    <td>Landing Page Visit</td>
                    {getGeneratedColumns(
                      "think",
                      accumulatedData,
                      "know_to_think"
                    )}
                  </tr>
                  <tr>
                    <td>Conversion Rate (%)</td>
                    {getGeneratedColumns(
                      "think",
                      accumulatedData,
                      "know_to_think_pct"
                    )}
                  </tr>
                </tbody>
              </Table>
            </>
          ) : null}

          {accumulatedData.do_data ? (
            <>
              <div className="roundHeading">Round 3</div>
              <Table bordered >
                <thead>
                  <tr className='tableHeader' >
                    <td className ='columnWidth' rowSpan={2}>Funnel Stage</td>
                    <td className ='columnWidth'>Media KPI</td>
                    {playerList?.map((player: string) => {
                      return <th key={player}>{player}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={2} className="headerTd">
                      DO
                    </td>
                    <td>Click on “buy now” ad</td>
                    {getGeneratedColumns("do", accumulatedData, "think_to_do")}
                  </tr>
                  <tr>
                    <td>Conversion Rate (%)</td>
                    {getGeneratedColumns(
                      "do",
                      accumulatedData,
                      "think_to_do_pct"
                    )}
                  </tr>
                </tbody>
              </Table>
            </>
          ) : null}
        
          <div className="roundHeading">
            { accumulatedData.do_data && accumulatedData.think_data ?  'Final Scores' : 'Scores' }
          </div>

          <div className="scoreTable">
            <Table bordered >
              <thead >
                <tr className='tableHeader' >
                  <th className='playerColumn'>Player</th>
                  <th className='scoreColumn'>Score</th>
                </tr>
              </thead>
              <tbody>
                {
                  playerList.map((player: string)=> {
                    return <tr key={player}>
                      <td>{player}</td>
                      <td>{  playerScores[player].toFixed(0) }</td>
                    </tr>
                  })
                }
              </tbody>
            </Table>
          </div>

        </div>
      ) : (
        <p className="noRoundMsg">
          There are no round results available for this game yet.
        </p>
      )}
    </>
  );
}
