import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store';
import { v4 as uuid } from 'uuid';
import { Player } from 'types/API/WarGamingAPI';
import { TransactionDataSet } from 'components/Graphs/Types';

export type Option<ValueType = string> = {
  label: string;
  value: ValueType;
  id?: string;
}

type groupByKeys = Extract<keyof TransactionDataSet, 'brand' | 'basket_size' | 'customer_group' | 'region' | 'store_type'>

export const groupByOptions: Option<groupByKeys>[] = [
  { label: 'Brand', value: 'brand', id: uuid() },
  { label: 'Basket Size', value: 'basket_size', id: uuid() },
  { label: 'Customer Group', value: 'customer_group', id: uuid() },
  { label: 'Region', value: 'region',  id: uuid() },
  { label: 'Store Type', value: 'store_type', id: uuid() },
]

export const regionOptions: Option[] = [
  { label: 'Rural', value: 'rural', id: uuid() },
  { label: 'SubUrban', value: 'suburban', id: uuid() },
  { label: 'Urban', value: 'urban', id: uuid() }
]

export const storeTypeOptions: Option[] = [
  { label: 'Local', value: 'local', id: uuid() },
  { label: 'Online', value: 'online', id: uuid() },
  { label: 'Super', value: 'super', id: uuid() }
]

export const customerGroupOptions: Option[] = [
  { label: "High income", value: "high", id: uuid() },
  { label: "Low income", value: "low", id: uuid() },
  { label: "Middle income", value: "middle", id: uuid() }
]

export let roundOptions: Option[] = []

export let brandOptions: Option[] = [];

export interface IFilters {
  groupBy: Option<groupByKeys>;
  rounds: Option[];
  brands: Option[];
  regions: Option[];
  storeTypes: Option[];
  customerGroups: Option[];
}

export interface FilterValues {
  groupBy: groupByKeys;
  rounds: string[];
  brands: string[];
  regions: string[];
  storeTypes: string[];
  customerGroups: string[];
}

const initialState: IFilters = {
  groupBy: groupByOptions[0],
  brands: [],
  regions: regionOptions,
  storeTypes: storeTypeOptions,
  customerGroups: customerGroupOptions,
  rounds: roundOptions,
}


const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    reset() {
      return {...initialState};
    },

    updateOptions(state, action: PayloadAction<{ players: Player[], currentRound: number }>) {
      brandOptions = action.payload.players.map(player => ({ id: uuid(), label: player.id, value: player.id }));
      // brandOptions
      state.brands = brandOptions;

      if (action.payload.currentRound) {
        const newRoundOptions: Option[] = []
        for (let round = 0; round < action.payload.currentRound; round ++) {
          newRoundOptions.push({ label: `Round ${round}`, value: `round${round}`, id: uuid() })
        }
        
        roundOptions = newRoundOptions;
        state.rounds = roundOptions;
      }
    },

    updateGroupByFilter (state, action: PayloadAction<{ id: string }>) {
      
      state.groupBy = groupByOptions.find(opt => opt.id === action.payload.id) || groupByOptions[0];
    },
    updateFilter (state, action: PayloadAction<{ key: keyof IFilters, value: Option[] }>) {
      if (action.payload.key === 'groupBy') {
        return;
      }
      state[action.payload.key] = action.payload.value
    },
  },
})

export const { updateFilter, updateGroupByFilter, updateOptions } = filtersSlice.actions

export default filtersSlice.reducer

export const selectFilters = (state: RootState) => state.filters
export const selectAllValues = (state: RootState): FilterValues => ({
  brands: state.filters.brands.map(opt => opt.value),
  customerGroups: state.filters.customerGroups.map(opt => opt.value),
  storeTypes: state.filters.storeTypes.map(opt => opt.value),
  regions: state.filters.regions.map(opt => opt.value),
  rounds: state.filters.rounds.map(opt => opt.value),
  groupBy: state.filters.groupBy.value,
})
export const selectFilterValues = (state: RootState) => (key: Exclude<keyof IFilters, 'groupBy'>) => state.filters[key].map(opt => opt.value)
export const selectFilterValue = (state: RootState) => (key: 'groupBy') => state.filters[key].value