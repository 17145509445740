import { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import {
  GraphConfig,
  GraphProps,
  TransactionDataSet,
} from "./Types";
import { backgroundColorArr, borderColorArr } from "./Utils";
import { getJSDocReadonlyTag } from "typescript";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Legend,
  Tooltip
);

export default function Graph(props: GraphProps<TransactionDataSet>) {
  
  const [lineData, setLineData] = useState<GraphConfig>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (props.data?.results) {
      let playersArray = props.data?.results.rounds.map((r: any) => r.players);
      let allPlayers = playersArray.reduce((accumulator: Array<any>, players) => {
        accumulator = [...accumulator, ...players];
        return accumulator;
      },[]);

      const playersById = allPlayers.reduce((accumulator, player) => {
        let values = player.metrics.revenue.map((rev: any) => rev.value );
        if(accumulator[player.id]){
            accumulator[player.id] = [...accumulator[player.id], ...values]
        }else{
            accumulator[player.id] = [...values]
        }
        return accumulator;
      }, {});

      const dataset = Object.keys(playersById).map((player, index) => {return {label: player, data: playersById[player], borderColor: borderColorArr[index],
      backgroundColor: backgroundColorArr[index] , type: "line"}})

      setLineData({
        labels: Array.from({length: props.data?.results && props.data?.results?.rounds.length * 13 }, (e,i) => i + 1 ),
        datasets: dataset 
      });
    }
  }, [props.data, setLineData]);

  const graphOptions = {
    responsive: true,

    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          filter: function (item: any, chart: any) {
            // Logic to remove a particular legend item goes here
            if (typeof item.text !== "object") {
              return item.text;
            }
            return "";
          },
          font: {
            size: 16,
          },
        },

      },

      title: {
        display: true,
        text: props.category.title,
        color: "#34495E",
        font: {
          size: 16,
        },
      }
    },

    pointRadius:4,

    scales: {
      x: {
        grid: {
          color: 'transparent',
          tickColor: "#dadee1", 
        },
        title:{
          text:'Week',
          display:true,
          font:{
            size:16,
            weight:'bold',
          },
        },

        suggestedMin: 5,
        suggestedMax: 5,
        ticks: {
          stepSize: 3,
          display:true,
        },
      },
      y: {
        // grid: {
        //   color: "#343432",
        // },
        title:{
          text:'Revenue',
          display:true,
          font:{
            size:16,
            weight:'bold',
          },
        },

        type: "linear" as const,
        display: true,
        position: "left" as const,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: any) {
            return props.yAxisLabel ? `${props.yAxisLabel} ${value.toLocaleString()}` : value.toLocaleString();
          },
        },
      },
    },
  };
  return <Chart type="line" options={graphOptions} data={lineData as any} className='revenueGraph'/>;
}
