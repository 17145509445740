import { configureStore } from "@reduxjs/toolkit";
import apiSlice from "./apiSlice";
import authSlice from "./authSlice";
import filtersSlice from "./filtersSlice";

export const store = configureStore({
  reducer: {
    apiData: apiSlice,
    filters: filtersSlice,
    auth: authSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
