import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import HTFSummary from "./HTF/HTFSummary";
import { BaseProps } from "./Types";
import { useDispatch, useSelector } from "react-redux";
import { selectFilters, updateOptions } from "../../store/filtersSlice";
import Financials from "./Finance/Financials";
import Graph from "./Graph";
import ACOSummary from "./ACO/ACOSummary";

export type Option = {
  label: string;
  value: string;
};

export default function Graphs(props: BaseProps) {
  const dispatch = useDispatch();
  const _filters = useSelector(selectFilters);
  const [tabValue, setTabvalue] = useState<string>("summary");

  useEffect(() => {
    dispatch(
      updateOptions({
        players: props.data.players,
        currentRound: props.data.currentRound,
      })
    );
  }, [props.data, dispatch]);

  const renderSummary = () => {
    return (
      <div>
        {props.data.gameType === "aco" ? (
          <ACOSummary {...props} />
        ) : (
          <HTFSummary {...props} filters={_filters} />
        )}
      </div>
    );
  };

  const renderFinancials = () => {
    return (
      <div>
        <Financials
          gameData={props.data}
          playerId={parseInt(props.playerId, 10)}
        />
      </div>
    );
  };

  function renderGraphs() {
    if (tabValue === "summary") {
      return renderSummary();
    } else if (tabValue === "finance") {
      return renderFinancials();
    }
  }

  return (
    <div>
      <div className="GraphTabs">
        <Button
          variant="outline-success"
          className={tabValue === "summary" ? "active-tab" : ""}
          size="lg"
          onClick={() => setTabvalue("summary")}
        >
          Output Results
        </Button>
        {/* <Button
          variant="outline-success"
          size="lg"
          className={tabValue === "finance" ? "active-tab ml-2" : "ml-2"}
          onClick={() => setTabvalue("finance")}
        >
          Your Performance
      </Button> */}
      </div>
      <div className="graphFilterWrapper">
        <div className="graphs">{renderGraphs()}</div>
      </div>
    </div>
  );
}
