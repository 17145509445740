import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { setRedirectRoute } from 'store/authSlice';
import { useAppDispatch } from 'store/hooks';

const ProtectedRoute = ({ isAuthed, isLoading, path, ...props }) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthed) {
    return <Redirect to={{ pathname: '/login' }} />
  }

  return (
    <Route {...props} />
  );
}

export default ProtectedRoute;