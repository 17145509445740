import { FinancialResultsInterface } from "../Types";

interface StatsToTableRowProps {
  stats: FinancialResultsInterface[];
  labelClass?: string;
  dataClass?: string;
}

const numberToString = (data: number) => {
  return Math.round(data).toLocaleString();
};

const StatsToTableRow = ({
  stats,
  labelClass = "font-weight-bold",
  dataClass = "",
}: StatsToTableRowProps) => {
  return (
    <>
      {stats.map((stat) => {
        return (
          <tr>
            {stat.name && <td className={labelClass}>{stat.name}</td>}
            {stat.data.map((statData: number) => {
              return (
                <td className={dataClass}>
                  {(stat.prefix || "£") +
                    numberToString(statData) +
                    (stat.suffix || "")}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

export default StatsToTableRow;
