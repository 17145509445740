import { useCallback, useEffect, useState } from "react";
import Graph from "../Graph";
import { SummaryProps } from "../Types";
import AccuRevGraph from "./AccuRevGraph";
import ACOTables from "./ACOTables";

export default function ACOSummary(props: SummaryProps) {
  return (
    <div>
      <Graph
        {...props}
        yAxisLabel="$"
        category={{ time: "", measure: "", title: "Revenue Graph" }}
      />
      <br />
      <AccuRevGraph
        {...props}
        yAxisLabel="$"
        category={{ time: "", measure: "", title: "Accumulated Revenue Graph" }}
      />

      <ACOTables game={props.data}/>
    </div>
  );
}
