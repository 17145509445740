import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./db/firebase";
import "bootswatch/dist/slate/bootstrap.min.css";
import TopNavBar from "components/Navigation/TopNavBar";
import { Route, Switch } from "react-router-dom";
import NewGame from "components/pages/NewGame/NewGame";
import GamePlay from "components/pages/GamePlay/GamePlay";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setUserId } from "store/userSlice";
import { selectAuthState } from "store/authSlice";
import MovesPage from "components/pages/Moves/MovesPage";
import Home from "components/pages/Home";
import Leaderboard from "components/pages/Leaderboard";
import Login from "components/pages/Login";
import ProtectedRoute from "components/Auth/ProtectedRoute.js";
import ModeratorPage from "components/pages/ModeratorPage/ModeratorPage";
import ModerateGame from "components/pages/ModerateGame/ModerateGame";

// const authState = auth;

function App() {
  const [user] = useAuthState(auth);

  const dispatch = useAppDispatch();
  const selector = useAppSelector(selectAuthState);
  const isAuthed = sessionStorage.getItem("auth") === "true";

  useEffect(() => {
    user && dispatch(setUserId(user.email));
  }, [dispatch, user]);

  return (
    <div className="App">
      {/* {authState && user ? */}
      <>
        <TopNavBar />
        {/* <Route
            path="/"
            exact
            component={Landing}
          /> */}
        <Switch>
          <ProtectedRoute isAuthed={isAuthed} isLoading={false} path="/" exact>
            <Home />
          </ProtectedRoute>
          <ProtectedRoute
            isAuthed={isAuthed}
            isLoading={false}
            path="/create-game"
          >
            <NewGame />
          </ProtectedRoute>
          <ProtectedRoute
            isAuthed={isAuthed}
            isLoading={false}
            path="/moderator-page"
          >
            <ModeratorPage />
          </ProtectedRoute>
          <ProtectedRoute
            isAuthed={isAuthed}
            isLoading={false}
            path="/moderate-game/:gameId"
          >
            <ModerateGame />
          </ProtectedRoute>
          <Route path="/login">
            <Login />
          </Route>
          <ProtectedRoute
            isAuthed={isAuthed}
            isLoading={false}
            path="/leaderboard"
          >
            <Leaderboard />
          </ProtectedRoute>
          {/* <Route
            path="/games"
            component={Games}
          /> */}
          {/* <Route
            path="/rooms"
            component={Rooms}
          /> */}
          <ProtectedRoute
            isAuthed={isAuthed}
            isLoading={false}
            path="/game/:gameId/player/:playerId"
          >
            <GamePlay />
          </ProtectedRoute>
          <ProtectedRoute
            isAuthed={isAuthed}
            isLoading={false}
            path="/moves/:gameId/player/:playerId"
          >
            <MovesPage />
          </ProtectedRoute>
        </Switch>
        {/* <LogOut /> */}
      </>
      {/* <LogIn /> */}
    </div>
  );
}

export default App;

// function LogIn(): ReactElement {

//   const signInWithGoogle = () => {
//     auth.signInWithPopup(googleAuthProvider);
//   }

//   return (
//    /* <Button onClick={signInWithGoogle}>Sign in with Google</Button>*/
//    <></>
//   )
// }

// function LogOut() {
//   return auth.currentUser && (
//     <Button onClick={() => auth.signOut()}>Sign Out</Button>
//   )
// }
