import { Alert, Button, Form } from "react-bootstrap";
import { auth } from "db/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setIsAuthed, selectAuthState } from "store/authSlice";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ssoLogin, getSsoUser } from "db/firebase";
const Login = (props: any) => {
  const [showLoginFail, setShowLoginFail] = useState(false);
  const { handleSubmit, control } = useForm();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const signInWithEmailPassword = async (email: string, password: string) => {
    var user = await signInWithEmailAndPassword(auth, email, password).catch(
      (error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
      }
    );
    if (user) {
      sessionStorage.setItem("auth", "true");
      dispatch(setIsAuthed(true));
      history.push("/");
    } else {
      setShowLoginFail(true);
    }
  };

  return (
    <div className="login-form-container mt-4">
      <Form
        className="login-form"
        onSubmit={handleSubmit((d) => {
          setShowLoginFail(false);
          signInWithEmailPassword(d.email, d.password);
        })}
      >
        {showLoginFail && (
          <p className="login-error">
            Login Failed! Username or Password is not correct.
          </p>
        )}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Controller
            control={control}
            name="email"
            defaultValue=""
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Form.Control
                onChange={onChange}
                value={value}
                ref={ref}
                type="email"
                placeholder="Enter email"
              />
            )}
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Controller
            control={control}
            name="password"
            defaultValue=""
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Form.Control
                onChange={onChange}
                value={value}
                ref={ref}
                type="password"
                placeholder="Password"
              />
            )}
          />
        </Form.Group>

        <Button variant="success" block type="submit">
          Submit
        </Button>
        <Button
          variant="outline-success"
          block
          type="button"
          onClick={ssoLogin}
        >
          Login using SSO
        </Button>
      </Form>
    </div>
  );
};

export default Login;
