import { ReactElement, useState } from "react";

import { Button, Col, Container, Row } from "react-bootstrap";

import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getValueChild } from "../../../db/firebase";

export default function ModeratorPage(): ReactElement {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [gameId, setGameId] = useState<string>("");
  const [error, setError] = useState<string>("");

  const onSubmit: () => Promise<void> = async () => {
    const gamePath: string = `games/${gameId}`;
    const response = await getValueChild(gamePath);
    if (!response) {
      return setError("Please enter a valid game id");
    }
    return history.push(`/moderate-game/${gameId}`);
  };

  return (
    <Container fluid className="flex-column  ">
      <Container fluid>
        <Row
          style={{ minHeight: "8rem", maxWidth: "980px" }}
          className="mt-5  change-flex-mobile "
        >
          <Button
            type="button"
            variant="outline-success"
            className="align-self-start mt-5 align-center-mobile"
            onClick={() => history.push("/create-game/")}
          >
            Create game
          </Button>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Col className="change-flex-mobile mt-5 flex-grow-1">
              <Col
                md="auto"
                className="d-flex flex-column flex-grow-1  change-flex-mobile mb-4  w-100"
              >
                <Col className=" d-flex flex-column flex-grow-1">
                  <input
                    className="w-100 p-2 align-self-start"
                    placeholder="Enter game id"
                    type="text"
                    {...register("gameIDField", {
                      required: "Please enter a game id",
                    })}
                    value={gameId}
                    onChange={(e) => {
                      setGameId(e.target.value);
                      setError("");
                    }}
                  ></input>
                  {errors.gameIDField && <p>{errors.gameIDField.message}</p>}
                  {!!error && <p>{error}</p>}
                </Col>
              </Col>
              <Button
                type="submit"
                variant="dark"
                className="align-self-start align-center-mobile"
              >
                Moderate
              </Button>
            </Col>
          </form>
        </Row>
      </Container>
    </Container>
  );
}
