import { ReactElement, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Graphs from "../../../components/Graphs/Graphs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGame,
  selectApiData,
  selectApiError,
  selectApiStatus,
} from "../../../store/apiSlice";

export default function GamePlay(): ReactElement {
  let { playerId, gameId } = useParams<{ playerId: string; gameId: string }>();

  const dispatch = useDispatch();

  const gameInfo = useSelector(selectApiData);
  const status = useSelector(selectApiStatus);
  const error = useSelector(selectApiError);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchGame(gameId));
    }
  }, [status, gameId, dispatch]);

  if (status === "loading") {
    return (
      <div className="loadContainer">Loading your data, please hold on.</div>
    );
  } else if (status === "failed") {
    console.error(error);
    return <div>Error getting game with id: {gameId}</div>;
  }
  return (
    <>
      <div className="GamesPlayHeader">
        <h1>Welcome to game: {gameInfo.name}</h1>
        <h1>Round number: {gameInfo.currentRound}</h1>
        {Boolean(gameInfo.players.length) && (
          <h1>Player: {gameInfo.players[parseInt(playerId)].id}</h1>
        )}
        <h1>Game Type: {gameInfo.gameType}</h1>

        <Link to={"/moves/" + gameId + "/player/" + playerId}>
          <Button variant="outline-success" size="lg">
            Add Moves
          </Button>
        </Link>
      </div>

      <div className="graphs">
        <Graphs data={gameInfo} playerId={playerId} />
      </div>
    </>
  );
}
