import { useCallback, useEffect, useState } from "react";
import { EndOfYearMetrics, Rounds } from "types/API/WarGamingAPI";
import { Table } from "react-bootstrap";
import StatsToTableRow from "./StatToTableRow";
import { FinancialResultsInterface, FinancialsInterface } from "../Types";

const FinanceTablePercentageChange = ({ gameData, playerId }: FinancialsInterface) => {
  const [summaryStats, setSummaryStats] = useState<FinancialResultsInterface[]>(
    []
  );
  const [cogsStats, setCogsStats] = useState<FinancialResultsInterface[]>([]);
  const [basketStats, setBasketStats] = useState<FinancialResultsInterface[]>(
    []
  );
  const [capexStats, setCapexStats] = useState<FinancialResultsInterface[]>([]);
  const [endDates, setEndDates] = useState<string[]>([]);

  const getDataFromResults = (
    results: Rounds,
    playerName: string,
    attribute: string
  ): number[] => {
    return Object.values(results)
      .slice(1)
      .map((round) => {
        const metrics = round.metrics.eoy_metrics.player[playerName];
        return metrics[attribute as keyof EndOfYearMetrics];
      });
  };

  const getFinancialResult = useCallback(
    (
      results: Rounds,
      playerName: string,
      attribute: string
    ): FinancialResultsInterface => {
      return {
        data: getDataFromResults(results, playerName, attribute),
        prefix: " ",
        suffix: "%",
      };
    },
    []
  );

  const getPCColumnHeader = (year: string): string => {
    let shortYear = year.slice(2);
    return shortYear + " vs " + (Number(shortYear) - 1);
  };

  useEffect(() => {
    const { results, players } = gameData;

    let playerName = "";

    if (players) {
      const gamePlayers = players.map((player) => player.id);
      playerName = gamePlayers[playerId];
    }

    if (results) {
      const endDatesArray = Object.values(results)
        .slice(1)
        .map((round) => {
          return round.end_date;
        });
      setEndDates(endDatesArray);

      setSummaryStats([
        getFinancialResult(results, playerName, "revenue_pc"),
        getFinancialResult(results, playerName, "basket_cogs_pc"),
        getFinancialResult(results, playerName, "gross_margin_pc"),
        getFinancialResult(results, playerName, "opex_pc"),
        getFinancialResult(results, playerName, "ebitda_pc"),
      ]);

      setCogsStats([
        getFinancialResult(results, playerName, "basket_cost_pc"),
        getFinancialResult(results, playerName, "aco_score_cost_pc"),
        getFinancialResult(results, playerName, "carbon_tax_pc"),
      ]);

      setBasketStats([
        getFinancialResult(results, playerName, "avg_basket_value_pc"),
        getFinancialResult(results, playerName, "vol_of_basket_pc"),
        getFinancialResult(results, playerName, "share_of_basket_vol_pc"),
      ]);
      setCapexStats([
        getFinancialResult(results, playerName, "capital_available_pc"),
        getFinancialResult(results, playerName, "capital_spent_pc"),
        getFinancialResult(results, playerName, "capital_rolled_over_pc"),
      ]);
    }
  }, [gameData, getFinancialResult, playerId]);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th colSpan={endDates.length} className="text-center">
            {" "}
            Round % Change{" "}
          </th>
        </tr>
        <tr>
          {endDates.map((_: string, roundNum: number) => {
            return <th>Round {roundNum + 1}</th>;
          })}
        </tr>
        <tr>
          {endDates.map((date: string) => {
            return <th>{getPCColumnHeader(date?.split("/")[2])}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        <StatsToTableRow stats={basketStats} />

        <div style={{ paddingTop: "142.5px" }}></div>

        <StatsToTableRow stats={summaryStats.slice(0, 2)} />

        <StatsToTableRow
          stats={cogsStats}
          labelClass="cogsRowData"
          dataClass="cogsRowData"
        />

        <StatsToTableRow stats={summaryStats.slice(2)} />

        <div style={{ paddingTop: "142.5px" }}></div>

        <StatsToTableRow stats={capexStats} />
      </tbody>
    </Table>
  );
};

export default FinanceTablePercentageChange;
