import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface userState {
    id: string | null
}

const initialState: userState = {
    id: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserId: (state, action: PayloadAction<string | null>) => {
            state.id = action.payload
        }
    }
});

export const { setUserId } = userSlice.actions;

export const selectUserId = (state: RootState) => null;

export default userSlice.reducer;