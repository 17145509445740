import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import StatsToTableRow from "./StatToTableRow";
import { FinancialResultsInterface } from "../Types";

interface FinanceTableInterface {
  revenue: number[];
  basketCogs: number[];
  grossMargin: number[];
  opex: number[];
  ebitda: number[];
  avgBasketValue: number[];
  volOfBasket: number[];
  shareOfBasketVol: number[];
  capitalAvailable: number[];
  captialSpent: number[];
  captialRolledOver: number[];
  playerNames: string[];
  endDates: string[];
  basketCost: number[];
  ACOGoodsCost: number[];
  carbonTax: number[];
  playerId: number;
}

const FinanceTable = ({
  revenue,
  basketCogs,
  grossMargin,
  opex,
  ebitda,
  avgBasketValue,
  volOfBasket,
  shareOfBasketVol,
  capitalAvailable,
  captialSpent,
  captialRolledOver,
  playerNames,
  endDates,
  basketCost,
  ACOGoodsCost,
  carbonTax,
  playerId,
}: FinanceTableInterface) => {
  const [summaryStats, setSummaryStats] = useState<FinancialResultsInterface[]>(
    []
  );
  const [cogsStats, setCogsStats] = useState<FinancialResultsInterface[]>([]);
  const [basketStats, setBasketStats] = useState<FinancialResultsInterface[]>(
    []
  );
  const [capexStats, setCapexStats] = useState<FinancialResultsInterface[]>([]);

  useEffect(() => {
    setSummaryStats([
      { name: "Revenue", data: revenue },
      { name: "Cost of Goods Sold (COGs)", data: basketCogs },
      { name: "Gross Margin", data: grossMargin },
      { name: "OPEX", data: opex },
      { name: "EBITDA", data: ebitda },
    ]);

    setCogsStats([
      { name: "Basket Cost", data: basketCost },
      { name: "ACO Goods Cost", data: ACOGoodsCost },
      { name: "Carbon Tax", data: carbonTax },
    ]);

    setBasketStats([
      { name: "Average Basket Value", data: avgBasketValue },
      { name: "Volume of Baskets", data: volOfBasket, prefix: " " },
      {
        name: "Share of Volume",
        data: shareOfBasketVol,
        prefix: " ",
        suffix: "% ",
      },
    ]);
    setCapexStats([
      { name: "Capex Available", data: capitalAvailable },
      { name: "Capex Spent", data: captialSpent },
      { name: "Capex Rolled Over", data: captialRolledOver },
    ]);
  }, [
    revenue,
    basketCogs,
    basketCost,
    ACOGoodsCost,
    carbonTax,
    grossMargin,
    opex,
    ebitda,
    avgBasketValue,
    volOfBasket,
    shareOfBasketVol,
    capitalAvailable,
    captialSpent,
    captialRolledOver,
  ]);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th colSpan={endDates.length + 1} className="text-center">
            Round Data{" "}
          </th>
        </tr>
        <tr>
          <th>{playerNames[playerId]}</th>
          {endDates.map((_: string, roundNum: number) => {
            return <th>Round {roundNum}</th>;
          })}
        </tr>
        <tr>
          <th>Round End Date</th>
          {endDates.map((date: string) => {
            return <th>{date?.split("/")[2]}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        <StatsToTableRow stats={basketStats} />

        <div style={{ paddingTop: "142.5px" }}></div>

        <StatsToTableRow stats={summaryStats.slice(0, 2)} />

        <StatsToTableRow
          stats={cogsStats}
          labelClass="cogsRowData"
          dataClass="cogsRowData"
        />

        <StatsToTableRow stats={summaryStats.slice(2)} />

        <div style={{ paddingTop: "142.5px" }}></div>

        <StatsToTableRow stats={capexStats} />
      </tbody>
    </Table>
  );
};

export default FinanceTable;
